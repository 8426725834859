import { IntlError, IntlErrorCode } from 'next-intl';

export function onError(_error: IntlError) {
  // eslint-disable-next-line no-console
  console.log('There was an translation error');
}

export function getMessageFallback({
  namespace,
  key,
  error
}: {
  namespace: string | undefined;
  key: string;
  error: IntlError;
}) {
  const path = [namespace, key].filter(part => part !== null).join('.');

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return path + ' is not yet translated';
  } else {
    return 'Dear developer, please fix this message: ' + path;
  }
}
