'use client';

import { Link } from 'i18n';
import { useTranslations } from 'next-intl';

import css from '../../features/error/404.module.css';

type GlobalErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Page({ error }: GlobalErrorProps) {
  const t = useTranslations();

  const message = error?.message ?? t('error_500');

  return (
    <div className={css.container}>
      <h1>{t('problem_loading_page')}</h1>
      <p>{message}</p>
      <p>
        <Link href="/" replace={true}>
          {t('go_back_to_site_overview')}
        </Link>
      </p>
    </div>
  );
}
