import { useTranslations } from 'next-intl';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { getRequestConfig } from 'next-intl/server';
import { notFound } from 'next/navigation';

import {
  getMessageFallback,
  onError
} from '@app/_components/intl-client-provider/intl-functions';

const selectableLanguages = [
  { locale: 'en', name: 'English' },
  { locale: 'no', name: 'Norsk' },
  { locale: 'es', name: 'Español' }
] as const;

export type Language = (typeof selectableLanguages)[number];
export type Locale = Language['locale'];
export const languages = selectableLanguages as any as Language[];
export const locales = selectableLanguages.map(
  language => language.locale
) as any as Locale[];

export default getRequestConfig(async ({ locale }) => {
  // Validate that the incoming `locale` parameter is valid
  if (!locales.includes(locale as Locale)) notFound();

  return {
    messages: (await import(`../messages/${locale}.json`)).default,
    onError,
    getMessageFallback
  };
});

export const { usePathname, useRouter, Link, redirect, permanentRedirect } =
  createSharedPathnamesNavigation({
    locales: locales
  });

// Only to be used with IntlTranslation type
const useTranslationsType = () => {
  return useTranslations();
};

export type IntlTranslation = ReturnType<typeof useTranslationsType>;
